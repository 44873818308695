import styled, { CreateStyled, CSSObject } from '@emotion/styled';

interface Font extends CSSObject {
  color: string;
  fontFamily: string;
  fontSize: string;
  fontWeight:
    | number
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'bold'
    | 'normal'
    | 'bolder'
    | 'lighter';
}

interface ResponsiveVersions {
  smUrl?: string;
  mdUrl?: string;
  lgUrl?: string;
  xlUrl?: string;
}

export type Theme = {
  config: {
    headerOnHero: boolean;
  };
  // Breaking changes: separator strings -> CSSObject
  separator: CSSObject | null;
  images: {
    background?: string;
    bottom?: string;
    top?: string;

    // deprecated
    left?: CSSObject;
    right?: CSSObject;
    bottomResponsive?: {
      smUrl?: string;
      mdUrl?: string;
      lgUrl?: string;
      xlUrl?: string;
    };
    topResponsive?: {
      smUrl?: string;
      mdUrl?: string;
      lgUrl?: string;
      xlUrl?: string;
    };
  };
  responsiveImages: {
    background?: ResponsiveVersions;
    bottom?: ResponsiveVersions;
    top?: ResponsiveVersions;
  };
  colors: {
    // Todo: CSS color type
    background: string;
    primary: string;
    primaryDisabled: string;
    primaryHover: string;
    secondary: string;
    secondaryDisabled: string;
    secondaryHover: string;
    tertiary: string;
    tertiaryDisabled: string;
    tertiaryHover: string;
    alert: string;

    // Deprecated
    hover: string; // use `primaryHover`
  };
  // fonts section not final
  fonts: {
    s1: Font;
    s2: Font;
    s3: Font;
    s4: Font;
    s5: Font;
    s6: Font;
    tiny: Font;

    // Deprecated
    body: Font;
    nav: Font;
    title: Font;
    dateLocation: Font;
    sectionTitle: Font;
    itemTitle: Font;
    bodyStrong: Font;
  };
  components: {
    Nav: {
      Button?: CSSObject;
      Desktop?: CSSObject;
      Item?: CSSObject;
      Link?: CSSObject;
      LinkActive?: CSSObject;
      Mobile?: CSSObject;
    };
    BackgroundImage?: CSSObject;
    BottomImage?: CSSObject;
    Button?: CSSObject;
    ButtonHover?: CSSObject;
    Card?: CSSObject;
    ContentItem?: CSSObject;
    DateLocationLockup?: CSSObject;
    Dropdown?: CSSObject;
    Event?: CSSObject;
    Footer?: CSSObject;
    HeadlineLarge?: CSSObject;
    HeadlineSmall?: CSSObject;
    Hero?: CSSObject;
    InitialsDateLockup?: CSSObject;
    Input?: CSSObject;
    Link?: CSSObject;
    LinkHover?: CSSObject;
    Page?: CSSObject;
    Photo?: CSSObject;
    PhotoGallery?: CSSObject;
    SiteSubtitle?: CSSObject;
    SiteTitle?: CSSObject;
    SubEvent?: CSSObject;
    TopImage?: CSSObject;
    WeddingParty?: CSSObject;
    WeddingPartyPerson?: CSSObject;
    QuestionFlourish?: CSSObject;
    TimelineDotFlourish?: CSSObject;
    TimelineLineFlourish?: CSSObject;

    // Deprecated
    ActiveNavLink?: CSSObject; // use Nav.LinkActive
    DesktopNav?: CSSObject; // use Nav.Desktop
    HeaderDetails?: CSSObject; // use SiteSubtitle
    NavButton?: CSSObject; // use Nav.Button
    NavItem?: CSSObject; // use Nav.Item
    NavLink?: CSSObject; // use Nav.Link
    StyledDateLocation?: CSSObject; // use DateLocationLockup
    StyledInitialsDate?: CSSObject; // use InitialsDateLockup
  };
  lockups?: {
    Initials?: {
      customFont?: string;
      baseInitial?: CSSObject;
      firstInitial?: CSSObject;
      secondInitial?: CSSObject;
      background?: CSSObject;
    };
    Name?: {
      customFont?: string;
      container?: CSSObject;
      nameBase?: CSSObject;
      firstName?: CSSObject;
      fianceFirstName?: CSSObject;
      amp?: CSSObject;
    };
    DateLocation?: {
      customFont?: string;
      container?: CSSObject;
      date?: CSSObject;
      location?: CSSObject;
    };
  };
  liteSite?: {
    CardLayout?: {
      components?: {
        Background?: CSSObject;
        Button?: CSSObject;
        ButtonHover?: CSSObject;
        Card?: CSSObject;
        DateFlourish?: CSSObject;
        NameFlourish?: CSSObject;
        Seperator?: CSSObject;
      };
    };
    Nav?: {
      Background?: CSSObject;
      Link?: CSSObject;
      Button?: CSSObject;
    };
  };
};

export default styled as CreateStyled<Theme>;
