import capitalize from 'lodash/capitalize';
import numberConverter from 'number-to-words';

import getDatePartValue from './getDatePartValue';

// This takes a date and converts it into words using a template string
// e.g.: dateTimeToWords(date, '%M %DOW, %y at %HW%MW %b') => "February Twelfth, 2022 at Twelve-Fifteen AM"

// Here's the key:
// weekday: %E => Monday
// month: %M => January
// day: %d => 25
// dayOrdinalWords: %dow => twenty-fifth
// dayOrdinalWordsTitle: %DOW => Twenty-Fifth
// year: %y => 2020
// yearWords: %yw => two thousand twenty-two
// yearWordsTitle: %YW => Two Thousand Twenty-Two
// hour: %h => 11
// hourWords: %hw => eleven
// hourWordsTitle: %HW => Eleven
// minute: %m => 59
// minuteWords: %mw => fifty-nine
// minuteWordsTitle: %MW => -Fifty-Nine
// dayPeriod: %b => PM
// dayPeriodWords: %bw => In The Evening

function getDateParts(date: Date): Intl.DateTimeFormatPart[] {
  const formatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  });
  return formatter.formatToParts(date);
}

const toWords = (input: string) => numberConverter.toWords(Number(input));
const toWordsOrdinal = (input: string) => numberConverter.toWordsOrdinal(Number(input));
const toWordsStripCommas = (input: string) => toWords(input).replace(/,/g, '');
const titleCase = (input: string): string =>
  input
    .split(/([\s-]+)/g)
    .map(capitalize)
    .join('');
const wordsIfNotZero = (input: string) => (input === '00' ? '' : toWords(input));
const dashWordsIfExists = (input: string) => (input.length === 0 ? '' : `-${titleCase(input)}`);

function addCustomDateParts(dateParts: Intl.DateTimeFormatPart[]) {
  const parts = [...dateParts];
  const additions = [
    {
      sourceKey: 'year',
      key: 'yearWords',
      transform: toWordsStripCommas,
    },
    { sourceKey: 'yearWords', key: 'yearWordsTitle', transform: titleCase },
    { sourceKey: 'day', key: 'dayOrdinalWords', transform: toWordsOrdinal },
    {
      sourceKey: 'dayOrdinalWords',
      key: 'dayOrdinalWordsTitle',
      transform: titleCase,
    },
    { sourceKey: 'hour', key: 'hourWords', transform: toWords },
    { sourceKey: 'hourWords', key: 'hourWordsTitle', transform: titleCase },
    {
      sourceKey: 'minute',
      key: 'minuteWords',
      transform: wordsIfNotZero,
    },
    {
      sourceKey: 'minuteWords',
      key: 'minuteWordsTitle',
      transform: dashWordsIfExists,
    },
    {
      sourceKey: 'dayPeriod',
      key: 'dayPeriodWords',
      transform: (input: string) => (input === 'AM' ? 'In The Morning' : 'In The Evening'),
    },
  ];

  additions.forEach(({ sourceKey, key, transform }) => {
    const part = parts.find((p) => p.type === sourceKey);
    if (part) {
      parts.push({
        type: key as Intl.DateTimeFormatPartTypes,
        value: transform(part.value),
      });
    }
  });

  return parts;
}

interface TemplateReplacements {
  key: string;
  regex: RegExp;
}

const templateReplacements: TemplateReplacements[] = [
  { key: 'weekday', regex: /%E/g },
  { key: 'dayOrdinalWords', regex: /%dow/g },
  { key: 'dayOrdinalWordsTitle', regex: /%DOW/g },
  { key: 'day', regex: /%d/g },
  { key: 'yearWords', regex: /%yw/g },
  { key: 'yearWordsTitle', regex: /%YW/g },
  { key: 'year', regex: /%y/g },
  { key: 'hourWords', regex: /%hw/g },
  { key: 'hourWordsTitle', regex: /%HW/g },
  { key: 'hour', regex: /%h/g },
  { key: 'minuteWords', regex: /%mw/g },
  { key: 'minuteWordsTitle', regex: /%MW/g },
  { key: 'minute', regex: /%m/g },
  { key: 'month', regex: /%M/g },
  { key: 'dayPeriodWords', regex: /%bw/g },
  { key: 'dayPeriod', regex: /%b/g },
];

export default function dateTimeToWords(date: Date, template: string) {
  const dateParts = getDateParts(date);
  const customDateParts = addCustomDateParts(dateParts);

  let formattedDate = template;
  templateReplacements.forEach(({ key, regex }) => {
    formattedDate = formattedDate.replace(regex, getDatePartValue(customDateParts, key));
  });

  return formattedDate;
}
