import dateTimeToWords from './dateTimeToWords';

const formats: { [key: string]: any } = {
  short: {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  },
  natural: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  weekday: {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  verbose: {},
  ornate: {},
  verboseDateOnly: {},
  ornateDateOnly: {},
  hoursMinutes: {
    hour: 'numeric',
    minute: 'numeric',
  },
};
const wordyFormats = ['verbose', 'verboseDateOnly', 'ornate', 'ornateDateOnly'];
const localizedFormats = ['short', 'hoursMinutes'];

export default function dateTimeFormatted(datetime?: Date | string | null, format?: string | null) {
  if (!datetime) return null;
  const dateFormat = format || 'natural';

  let date;
  if (typeof datetime === 'string') {
    const datestr = datetime.match(/\d\d:\d\d/) ? datetime : `${datetime} 00:00:00 UTC`;
    date = new Date(datestr.replace(/-/g, '/')); // Safari does not like dashes in dates
  } else {
    date = datetime;
  }

  if (!(date instanceof Date) || Number.isNaN(date.valueOf()) || !Number.isFinite(date.valueOf())) {
    return '';
  }

  let formattedDate = '';
  const wordyFormat = wordyFormats.includes(dateFormat);
  if (!wordyFormat) {
    const options = {
      timeZone: 'UTC',
      ...formats[dateFormat],
    };
    const localizedFormat = localizedFormats.includes(dateFormat);
    const locale = localizedFormat ? undefined : 'en-US';

    formattedDate = date.toLocaleString(locale, options).replace(/\//g, '.');
  } else {
    const templates: { [key: string]: string } = {
      verbose: "%E, %M %d, %y at %HW%MW O'Clock",
      verboseDateOnly: '%E, %M %d, %y',
      ornate: "%E, %M %DOW, %YW at %HW%MW O'Clock %bw",
      ornateDateOnly: '%E, %M %DOW, %YW',
    };

    formattedDate = dateTimeToWords(date, templates[dateFormat]);
  }

  return formattedDate;
}
